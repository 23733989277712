/*
 * Hubspot forms restyle
 *
 * Restyles Hubspot iframe forms. Injects our styles (based on ably-ui) into an iframe.
 */

/* global hbspt */

import { connectState, selectSessionData, getRemoteDataStore } from '@ably/ui/core/scripts';

const hubspotFormsCss = require('!!css-to-string-loader!css-loader!./hubspot-forms.css');

const WAIT_BETWEEN_RETRIES_MS = 300;

const restyleHubspotForm = (formIframeSelector) => {
  let retries = 10;

  const waitForIframe = () => {
    const iframe = document.querySelector(formIframeSelector);

    if (iframe) {
      const style = document.createElement('style');
      style.appendChild(document.createTextNode(`${hubspotFormsCss}\n`));
      iframe.contentDocument.head.appendChild(style);
    } else if (retries > 0) {
      retries -= 1;
      setTimeout(waitForIframe, WAIT_BETWEEN_RETRIES_MS);
    }
  };

  waitForIframe();
};

const setFormValues = ({ firstname, lastname, email, session }) => {
  if (!session || !session.user) return;

  firstname.val(session.user.firstName).change();
  lastname.val(session.user.lastName).change();
  email.val(session.user.email).change();
};

// form is a jQuery node
const hubspotOnFormReady = (form) => {
  const firstname = form.find('[name=firstname');
  const lastname = form.find('[name=lastname');
  const email = form.find('[name=email');

  const firstnameValue = firstname.val();
  const lastnameValue = lastname.val();
  const emailValue = email.val();

  if (!!firstnameValue || !!lastnameValue || !!emailValue) return;

  const store = getRemoteDataStore();
  const currentSessionData = selectSessionData(store);

  if (currentSessionData) {
    setFormValues({ firstname, lastname, email, session: currentSessionData });
  } else {
    connectState(selectSessionData, (session) => setFormValues({ firstname, lastname, email, session }));
  }
};

// Resize an iframe according to dimentions of it's parent container
//
// This function returns a bound event handler
// e.g. window.addEventListener("resize", resizeIframeByContainer(".my-iframe", "#my-container"));
//
const resizeIframeByContainer = (iframeSelector, containerSelector) => {
  const iframe = document.querySelector(iframeSelector);
  const container = document.querySelector(containerSelector);

  if (!iframe || !container) throw new Error('Require both iframe and container DOM elements');

  return () => {
    const { clientWidth, clientHeight } = container;

    iframe.style.width = `${clientWidth}px`;
    iframe.style.height = `${clientHeight}px`;
  };
};

const createForm = (props) =>
  hbspt.forms.create({
    region: 'na1',
    portalId: '6939709',
    ...props,
  });

export { createForm, hubspotOnFormReady, restyleHubspotForm, resizeIframeByContainer };
